<template>
  <div v-if="hasCancelBtn" class="footerBar twoBtn">
    <div class="cancelBtn" @click="clickCancel">{{ cancelText }}</div>
    <div class="confirmBtn" @click="clickConfirm">{{ confirmText }}</div>
  </div>
  <div v-else class="footerBar oneBtn" :class="{'disabled': disabled}" @click="clickConfirm">
    {{ confirmText }}
  </div>
</template>

<script>
// import {} from ''
export default {
  name: 'FooterBar',
  props: {
    hasCancelBtn: {
      type: Boolean,
      default: false
    },
    confirmText: {
      type: String,
      default: '确定'
    },
    cancelText: {
      type: String,
      default: '取消'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {
  },
  methods: {
    clickConfirm() {
      if (this.disabled) return;
      this.$emit('clickConfirm');
    },
    clickCancel() {
      this.$emit('clickCancel');
    }
  }
};
</script>

<style lang="scss" scoped>
.footerBar{
  width: 100%;
  height: 98px;
  line-height: 98px;
  color: #fff;
  font-size: 32px;
  border-top:2px #EDEDED solid;
  text-align: center;
  position: fixed;
  left: 0;
  bottom: 0;
  &.oneBtn{
    background: $color-primary;
    &.disabled{
      background: #A7CBF4;
    }
  }
  &.twoBtn{
    display: flex;
    align-items: center;
    div{
      flex: 1;
    }
    .cancelBtn{
      color: $color-grey;
      background: #fff;
    }
    .confirmBtn{
      background: $color-primary;
    }
  }

}
</style>
