<template>
  <van-nav-bar :title="title" placeholder fixed class="headerBar">
    <template #left>
      <img v-if="isShowBackArrow" @click="$router.back()" class="backIcon" src="../../assets/images/back.png">
      <slot name="left"></slot>
    </template>
    <template #right>
      <slot name="right"></slot>
    </template>
  </van-nav-bar>
</template>

<script>
// import {} from ''
export default {
  name: 'HeaderBar',
  props: {
    title: {
      type: String,
      default: '标题'
    },
    isShowBackArrow: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {
  },
  methods: {}
};
</script>

<style lang="scss">
  .headerBar{
    .van-nav-bar__title{
      font-size: 36px;
      font-weight: normal;
    }
    .backIcon{
      width: 48px;
    }
  }

</style>
