<template>
  <van-popup
    v-model="isShow"
    class="bottomPopup"
    position="bottom"
    :style="{ height: '138px' }"
  >
    <section class="contentWrap">
      <img :src="require(`@/assets/images/${icon}.png`)" alt="">
      <h3>{{title}}</h3>
    </section>
    <footer-bar
      :has-cancel-btn="hasCancelBtn"
      @clickCancel="clickCancel"
      @clickConfirm="clickConfirm"
    />
  </van-popup>
</template>

<script>
// import { } from 'vuex'
import FooterBar from '@/components/FooterBar/index';
export default {
  name: 'BottomPopup',
  components: { FooterBar },
  props: {
    hasCancelBtn: {
      type: Boolean,
      default: false
    },
    isShow: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
    };
  },
  computed: {
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    clickConfirm() {
      this.$emit('clickConfirm');
    },
    clickCancel() {
      this.$emit('clickCancel');
    }
  }

};
</script>

<style lang="scss" scoped>
.bottomPopup{

  .contentWrap{
    height: 172px;
    padding: 32px 32px 132px;
    display: flex;
    img{
      width: 74px;
      height: 74px;
    }
    h3{
      font-size: 36px;
      line-height: 50px;
      padding: 12px 18px;
    }
  }
}
</style>
